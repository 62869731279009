<template>
    <ui-card class="stat-card">
        <div class="stat-card__container">
            <div>
                <h6 class="stat-card__title">{{ title }}</h6>
                <p class="stat-card__number">{{ number }}</p>
            </div>
            <span class="stat-card__icon" :class="[icon, `stat-card__icon--${iconVariant}`]"></span>
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card';

export default {
    name: 'StatCard',
    components: { UiCard },
    props: {
        title: {
            type: String,
            default: null,
            required: true,
        },
        number: {
            type: String,
            default: null,
            required: true,
        },
        icon: {
            type: String,
            default: 'fas fa-chart-line',
            required: false,
        },
        iconVariant: {
            type: String,
            default: 'info',
            required: false,
        },
    },
};
</script>

<style lang="scss">
.stat-card {
    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        color: $ecart-secondary-500;
        font-weight: 600;
    }

    &__number {
        color: $general-black;
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 0;
    }

    &__icon {
        align-items: center;
        background-color: $ecart-secondary-300;
        border-radius: 100%;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 1.2rem;
        height: 40px;
        justify-content: center;
        line-height: 1;
        width: 40px;

        &--info {
            background-color: rgba($general-info, 0.2);
            color: $general-info;
        }
        
        &--success {
            background-color: rgba($general-success, 0.2);
            color: $general-success;
        }

        &--warning {
            background-color: rgba($general-warning, 0.2);
            color: $general-warning;
        }

        &--danger {
            background-color: rgba($general-error, 0.2);
            color: $general-error;
        }
    }
}
</style>
