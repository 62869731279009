<template>
    <ui-card class="card-skeleton">
        <ui-skeleton class="w-50 mb-1" />
        <ui-list>
            <ui-list-item>
                <template #title>
                    <ui-skeleton class="w-75" />
                </template>
                <template #text>
                    <ui-skeleton class="w-100" />
                </template>
            </ui-list-item>
            <ui-list-item>
                <template #title>
                    <ui-skeleton class="w-75" />
                </template>
                <template #text>
                    <ui-skeleton class="w-100" />
                </template>
            </ui-list-item>
            <ui-list-item>
                <template #title>
                    <ui-skeleton class="w-75" />
                </template>
                <template #text>
                    <ui-skeleton class="w-100" />
                </template>
            </ui-list-item>
            <ui-list-item>
                <template #title>
                    <ui-skeleton class="w-75" />
                </template>
                <template #text>
                    <ui-skeleton class="w-100" />
                </template>
            </ui-list-item>
            <ui-list-item>
                <template #title>
                    <ui-skeleton class="w-75" />
                </template>
                <template #text>
                    <ui-skeleton class="w-100" />
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card'
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'CardSkeleton',
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiSkeleton,
    },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        },
    },
    data() {
        return {};
    },
};
</script>
