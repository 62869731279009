<template>
    <div class="ui-tabs">
        <div class="ui-tabs__header">
            <ul class="ui-tabs__nav">
                <li
                    v-for="(tab, index) in tabs"
                    :key="tab.title"
                    class="ui-tabs__nav-item"
                    :class="{ 'ui-tabs__nav-item--selected': (index === selectedIndex) }"
                    @click="selectTab(index)"
                >
                    {{ tab.title }}
                </li>
            </ul>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    provide() {
        return {
            getTabsInstance: () => this,
        };
    },
    data () {
        return {
            selectedIndex: 0,
            tabs: [],
        };
    },
    mounted() {
        this.selectTab(0);
    },
    methods: {
        selectTab(i) {
            this.selectedIndex = i;

            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === i);
            });
        },
        registerTab(tab) {
            if (this.tabs.indexOf(tab) === -1) {
                this.tabs.push(tab);
            }
        },
    },
};
</script>

<style lang="scss">
.ui-tabs {
    &__header {
        overflow-x: auto;
    }

    &__nav{
        display: flex;
        list-style: none;
        margin-bottom: 1rem;
        padding: 0;
    }

    &__nav-item {
        align-items: center;
        border-bottom: 2px solid $ecart-secondary-200;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: center;
        line-height: 110%;
        margin: 0;
        margin-bottom: -2px;
        padding: 16px 8px;
        text-align: center;
        width: 100%;

        &--selected {
            font-weight: bold;
            border-color: $ecart-primary;
        }
    }
}
</style>
