<template>
    <div class="pricings-table">
        <ui-pricing-list-skeleton v-if="loading" :rows="1" />
        <template v-else>
            <ui-card v-for="pricing in pricings" :key="pricing.id">
                <div class="pricings-table__row">
                    <div class="pricings-table__left-column">
                        <h5>
                            Pricing ({{ pricing.name }})
                        </h5>
                        <ui-list>
                            <ui-list-item title="Country:">
                                <template #text>
                                    <img
                                        v-if="pricing.country" class="pricings-table__flag"
                                        :src="`https://envia-clients-new.herokuapp.com/flags/${pricing.country.toLowerCase()}.svg`"
                                        :alt="pricing.country"
                                    />
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Status:">
                                <template #text>
                                    <ui-status class="fs-12px mx-0 my-0" :status="pricing.active ? 'active' : 'inactive'" />
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Minimum amount:">
                                <template #text>
                                    <b>{{ pricing.amount.min }}</b>
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Maximum amount:">
                                <template #text>
                                    <b>{{ pricing.amount.max }}</b>
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Created at:">
                                <template #text>
                                    {{ $dayjs(pricing.created_at).format('LL, LT') }}
                                </template>
                            </ui-list-item>
                        </ui-list>
                    </div>
                    <div class="pricings-table__right-column">
                        <h5>
                            Available methods
                        </h5>
                        <ui-list-group bordered>
                            <ui-list-group-item
                                v-for="method in pricing.methods.slice(0, activeShowMoreMethods[pricing.id] ? pricing.methods.length : 3)"
                                :key="method.brand"
                                padding-variant="sm"
                            >
                                <div class="pricings-table__method-container">
                                    <img class="gateway-item__icon" :src="getSrc(method.brand)" :alt="method.brand">
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                                <h6 class="pricings-table__method-title">Brand:</h6>
                                                <p class="pricings-table__method-text">
                                                    {{ method.brand.replace('_', ' ') }}
                                                </p>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                                <h6 class="pricings-table__method-title">Type:</h6>
                                                <p class="pricings-table__method-text text-capitalize">{{ method.type }}</p>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4 d-flex">
                                                <h6 class="pricings-table__method-title">Installments:</h6>
                                                <p class="pricings-table__method-text">
                                                    {{ method.installments }}
                                                </p>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                                <h6 class="pricings-table__method-title">Percent:</h6>
                                                <p class="pricings-table__method-text">
                                                    {{ method.percent }}%
                                                </p>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-0 d-flex">
                                                <h6 class="pricings-table__method-title">Fixed:</h6>
                                                <p class="pricings-table__method-text">
                                                    {{ method.fixed }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ui-list-group-item>
                            <ui-list-group-item class="pricings-table__show-more-button" @click="toggleMoreMethods(pricing.id)">
                                <i class="fas" :class="activeShowMoreMethods[pricing.id] ? 'fa-minus-circle' : 'fa-plus-circle'"></i> 
                                Show {{ activeShowMoreMethods[pricing.id] ? 'less' : 'more' }}
                            </ui-list-group-item>
                        </ui-list-group>
                    </div>
                </div>
            </ui-card>
        </template>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card';
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiListGroup from '@/components/ui/list-group/ListGroup';
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem';
import UiPricingListSkeleton from '@/components/ui/skeleton/PricingListSkeleton';
import UiStatus from '@/components/ui/BadgeStatus';

export default {
    name: 'PricingsTable',
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiListGroup,
        UiListGroupItem,
        UiPricingListSkeleton,
        UiStatus,
    },
    data() {
        return {
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'country', label: 'Country' },
                { key: 'amount', label: 'Amount' },
                { key: 'created_at', label: 'Created at' },
                { key: 'active', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            loading: false,
            methods: [],
            pricings: null,
            activeShowMoreMethods: {},
        };
    },
    mounted() {
        this.getPricings();
    },
    methods: {
        getSrc(payment_method) {
            try {
                return require(`@/assets/img/payment-methods/${payment_method}.svg`);
            } catch (error) {
                return require(`@/assets/img/payment-methods/default.svg`);
            }
        },
        showMethodsModal(methods) {
            this.methods = methods;
            this.$refs.methodsModal.show();
        },
        async getPricings() {
            try {
                this.loading = true;

                const { data } = await this.$axios.get(`/_/pricings/${this.$route.query.id}/sumary`);

                this.pricings = data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        toggleMoreMethods(pricing_id) {
            if (this.activeShowMoreMethods[pricing_id]) {
                delete this.activeShowMoreMethods[pricing_id];
                return;
            }

            this.activeShowMoreMethods[pricing_id] = true;
        }
    },
};
</script>

<style lang="scss">
.pricings-table {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        @media(min-width: 1200px) {
            flex-direction: initial;
        }
    }

    &__left-column {
        max-width: 100%;
        width: 100%;
        flex-shrink: 0;
        
        @media(min-width: 1200px) {
            width: 350px;
        }
    }

    &__right-column {
        width: 100%;
    }

    &__method-container {
        display: flex;
        gap: 16px;
        width: 100%;
    }

    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        color: $general-black;
        font-weight: 600;
    }

    &__method-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        margin-right: 10px;
        width: 90px;
    }

    &__method-text {
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__show-more-button {
        color: $ecart-accent;
        cursor: pointer;
        font-weight: 600;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: center;
    }
}
</style>
