<template>
    <ui-card class="stat-card-skeleton">
        <div class="stat-card-skeleton__container">
            <div class="w-100">
                <ui-skeleton class="stat-card-skeleton__title" />
                <ui-skeleton class="stat-card-skeleton__number" />
            </div>
            <ui-skeleton class="stat-card-skeleton__icon" />
        </div>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card'
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'StatCardSkeleton',
    components: { UiCard, UiSkeleton },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.stat-card-skeleton {
    margin-bottom: 0;

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        height: 15px;
        width: 50%;
    }

    &__number {
        height: 37px;
        width: 70%;
    }

    &__icon {
        border-radius: 100%;
        flex-shrink: 0;
        height: 40px;
        width: 40px;
    }
}
</style>
