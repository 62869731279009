<template>
    <div class="card-money">
        <template v-if="summary">
            <span v-if="showTotal" class="lead fs-36px font-normal mb-4">{{ amountFormatted }}</span>
            <div v-if="summary.balances.length" class="bg-gray-100 p-2 rounded mx-auto" bg-variant="light">
                <div
                    v-for="item in summary.balances"
                    :key="item.currency"
                    class="d-flex justify-content-between lead py-1 text-gray-900 align-items-end"
                >
                    <p class="m-0">{{ item.currency }}</p>
                    <div class="card-money__dot col"></div>
                    <p class="m-0">{{ _currency(item.amount, item.currency) }}</p>
                </div>
            </div>
            <div v-else>
                <empty-state text="No balance movements found."/>
            </div>
        </template>
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState.vue';

export default {
    components: { EmptyState },
    props: {
        showTotal: {
            type: Boolean,
            default: true,
            required: false,
        },
        summary: {
            type: [Object, null],
            default: null,
        },
    },
    computed: {
        amountFormatted() {
            return this._currency(this.summary.total, this.summary.currency);
        },
    },
};
</script>

<style lang="scss" scoped>
.card-money {
    &__dot {
        border-bottom: dotted 2px lightgrey;
        height: 12px;
        margin: 0 5px;
        margin-bottom: 5px;
    }
}
</style>
