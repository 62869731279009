<template>
    <div class="pricing-list-skeleton">
        <ui-card v-for="index in rows" :key="index">
            <div class="pricing-list-skeleton__row">
                <div class="pricing-list-skeleton__left-column">
                    <ui-skeleton class="w-50 mb-3" />
                    <ui-list>
                        <ui-list-item title="Country:">
                            <ui-skeleton class="w-100" />
                        </ui-list-item>
                        <ui-list-item title="Status:">
                            <ui-skeleton class="w-100" />
                        </ui-list-item>
                        <ui-list-item title="Minimum amount:">
                            <ui-skeleton class="w-100" />
                        </ui-list-item>
                        <ui-list-item title="Maximum amount:">
                            <ui-skeleton class="w-100" />
                        </ui-list-item>
                        <ui-list-item title="Created at:">
                            <ui-skeleton class="w-100" />
                        </ui-list-item>
                    </ui-list>
                </div>
                <div class="pricing-list-skeleton__right-column">
                    <ui-skeleton class="w-50 mb-3" />
                    <ui-list-group bordered>
                        <ui-list-group-item
                            v-for="methodIndex in 3"
                            :key="methodIndex"
                            padding-variant="sm"
                        >
                            <div class="pricing-list-skeleton__method-container">
                                <ui-skeleton class="pricing-list-skeleton__method-icon" />
                                <div class="w-100">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                            <ui-skeleton class="w-100" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                            <ui-skeleton class="w-100" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-4 d-flex">
                                            <ui-skeleton class="w-100" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-0 d-flex">
                                            <ui-skeleton class="w-100" />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4 d-flex">
                                            <ui-skeleton class="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ui-list-group-item>
                    </ui-list-group>
                </div>
            </div>
        </ui-card>
    </div>
</template>

<script>
import UiList from '@/components/ui/list/List';
import UiListItem from '@/components/ui/list/ListItem';
import UiListGroup from '@/components/ui/list-group/ListGroup.vue';
import UiListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';
import UiCard from '@/components/ui/Card.vue';
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'PricingListSkeleton',
    components: {
        UiList,
        UiListItem,
        UiListGroup,
        UiListGroupItem,
        UiCard,
        UiSkeleton,
    },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        }
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.pricing-list-skeleton {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        @media(min-width: 1200px) {
            flex-direction: initial;
        }
    }

    &__left-column {
        max-width: 100%;
        width: 100%;
        flex-shrink: 0;
        
        @media(min-width: 1200px) {
            width: 350px;
        }
    }

    &__right-column {
        width: 100%;
    }

    &__method-container {
        display: flex;
        gap: 16px;
        width: 100%;
    }

    &__method-icon {
        border-radius: 10px;
        height: 50px;
        max-width: 100%;
        width: 50px;

        @media (max-width: 768px) {
            width: 40px;
            min-width: 40px;
        }
    }
}
</style>
