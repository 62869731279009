<template>
    <ui-card title="Amount withheld according to customer risk">
        <div class="withheld-amount__amount-container">
            <p class="withheld-amount__amount-title">
                Amount received
            </p>
            <span class="withheld-amount__amount-total">
                N/A
            </span>
        </div>
        <p class="withheld-amount__title">Totals</p>
        <ul class="withheld-amount__list list-group">
            <li class="withheld-amount__item list-group-item">
                <span class="withheld-amount__item-title">Withheld amount:</span>
                <span class="withheld-amount__item-separator"></span>
                <span class="withheld-amount__item-amount withheld-amount__item-amount--danger">
                    N/A
                </span>
            </li>
            <li class="withheld-amount__item list-group-item">
                <span class="withheld-amount__item-title">Total to deposit:</span>
                <span class="withheld-amount__item-separator"></span>
                <span class="withheld-amount__item-amount withheld-amount__item-amount--success">
                    N/A
                </span>
            </li>
        </ul>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';

export default {
    name: 'WithheldAmount',
    components: { UiCard },
};
</script>

<style lang="scss">
.withheld-amount {
    &__title {
        font-weight: 500;
        margin-bottom: 0;
    }

    &__paid-at {
        color: $ecart-secondary-500;
    }

    &__amount-container {
        border: 1px solid $ecart-secondary-100;
        border-radius: 8px;
        padding: 10px;
        text-align: center;

        hr {
            background-color: $ecart-secondary-100;
            margin-bottom: 10px;
            margin-top: 10px;
            opacity: 1;
        }
    }

    &__amount-title {
        margin-bottom: 10px;
        font-weight: 500;
    }

    &__amount-total {
        color: $general-success;
        font-size: 1.71rem;
        font-weight: 600;
        word-break: break-all;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    &__list {
        border-radius: 8px;
    }

    &__item {
        align-items: center;
        background-color: transparent;
        border-color: $ecart-secondary-100;
        display: flex;
        gap: 16px;

        &:not(:last-child) {
            border-bottom: 0;
        }
    }

    &__item-title {
        font-weight: 500;
    }

    &__item-total {
        font-weight: 500;
    }

    &__item-amount {
        font-weight: 500;
        color: $ecart-secondary-500;

        &--danger {
            color: $general-error;
            font-size: 1.2rem;
            font-weight: 600;
        }
        
        &--success {
            color: $general-success;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    &__item-separator {
        flex: 1;
        border-bottom: 1px dashed $gray-300;
    }
}
</style>
