<template>
    <div class="account-transactions">
        <transaction-table
            :account-id="$route.query.id"
            show-download-button
        />
    </div>
</template>

<script>
import TransactionTable from '@/components/transactions/TransactionTable';

export default {
    name: 'AccountTransactions',
    components: { TransactionTable },
};
</script>
