<template>
    <ui-card class="gateway-container"
        background-variant="white"
        padding-variant="usm"
        :shadow="false">
    <div style="background-color:#F8F8F8">
        <div class="gateway-item__adjust">
        <div class="gateway-item__details">
            <div class="gateway-item__details-content">
                <img class="gateway-item__icon" :src="getSrc(gateway.gateway)" :alt="gateway.gateway">
                <div>
                    <h6 class="gateway-item__title">{{ gateway.gateway }}</h6>
                    <ui-status class="fs-12px" :status="gateway.active ? 'enabled' : 'disabled'" />
                </div>
            </div>
        </div>
        <div class="gateway-item__actions" v-if="CAN(['write_accounts'])">
            <ui-switch
                :id="`${gateway.id}-active`"
                :name="`${gateway.id}-active`"
                :disabled="loading || !CAN(['write_accounts'])"
                v-model:value="active"
                @change="changeValue"
            />
        </div>
    </div>
    </div>
    <list-group-item v-for="methods in gateway.methods" class="py-1">
        <div class="gateway-item">
            <div class="gateway-item__details">
                <div class="gateway-item__details-content">
                    <p class="gateway-item__title">{{ methods.title || methods.type }}</p>
                </div>
            </div>
            <div class="gateway-item__actions">
            <input
                :id="`${methods.id}`"
                :name="`${methods.id}`"
                :disabled="loadingMethod || !CAN(['write_accounts'])"
                v-model="methods.active"
                @change="changeValueGateway"
                type="checkbox"
            />
        </div>
        </div>
    </list-group-item>
    </ui-card>
</template>

<script>
import UiStatus from '@/components/ui/BadgeStatus';
import UiInput from '@/components/ui/Input';
import UiButton from '@/components/ui/buttons/Button';
import UiSkeleton from '@/components/ui/skeleton/Skeleton';
import UiCard from '@/components/ui/Card.vue';
import UiSwitch from '@/components/ui/Switch.vue';
import ListGroup from '@/components/ui/list-group/ListGroup.vue';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';

export default {
    name: 'GatewayItem',
    components: {
        UiStatus,
        UiInput,
        UiButton,
        UiSkeleton,
        UiCard,
        UiSwitch,
        ListGroup,
        ListGroupItem,
    },
    props: {
        gateway: {
            type: Object,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            active: this.gateway.active,
            loading: false,
            loadingMethod: false,
            showDetails: null,
        };
    },
    methods: {
        handleShowDetails() {
            this.showDetails = !this.showDetails;
        },
        getSrc(gateway) {
            try {
                return require(`@/assets/img/gateways/${gateway}.svg`);
            } catch (error) {
                return require(`@/assets/img/gateways/default.svg`);
            }
        },
        async changeValue() {
            try {
                this.loading = true;

                const { data } = await this.$axios.put(`/_/accounts/${this.$route.query.id}/gateways/${this.gateway.id}`, {
                    active: this.active,
                });

                this.$emit('updateGatewayStatus', data.id, this.active);
                this.$toast.success('Gateway status updated successfully');
            } catch (error) {
                this.active = !this.active;
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async changeValueGateway(methodTarget) {
                const target = methodTarget.target.id;
                const arrayMethods = this.gateway.methods;
                this.loadingMethod = true;
                let methodFilter = arrayMethods.find(method => method.id === target);
            try {
                await this.$axios.put(`/_/accounts/${this.$route.query.id}/gateways/${this.gateway.id}/methods/${target}`, {
                    active: methodFilter.active,
                });

                this.$toast.success('Method status updated successfully');
            } catch (error) {
                methodFilter.active = !methodFilter.active;
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loadingMethod = false;
            }
        }
    },
};
</script>

<style lang="scss">
.gateway-container {
    height: 450px;
    scroll-behavior: auto;
    overflow-y: auto;
}
.gateway-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__details {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            gap: 8px;
        }
    }

    &__adjust {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__details-content {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &__details-gateway {
        margin-top: 1rem;
    }

    &__icon {
        border-radius: 10px;
        height: 50px;
        max-width: 100%;
        width: 50px;

        @media (max-width: 768px) {
            width: 40px;
            min-width: 40px;
        }
    }

    &__title {
        color: $general-black;
        font-size: 1.05rem;
        text-transform: uppercase;
    }

    &__actions {
        align-items: center;

        @media (max-width: 768px) {
            gap: 4px;
        }
    }
}
</style>