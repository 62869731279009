<template>
    <div class="gateway-list">
        <ui-list-group-skeleton v-if="loading" :rows="5" />
        <div class="row" v-else>
            <div class="col-sm-6 col-md-4 col-xl-3" v-for="gateway in gateways" :key="gateway.id">
                <gateway-item :gateway="gateway" @updateGatewayStatus="updateGatewayStatus" />
            </div>
            <list-group-item v-if="!gateways.length">
                <empty-state title="No gateways found..."/>
            </list-group-item>
        </div>
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState.vue';
import GatewayItem from '@/components/accounts/GatewayItem.vue';
import ListGroup from '@/components/ui/list-group/ListGroup.vue';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';
import UiListGroupSkeleton from '@/components/ui/skeleton/ListGroupSkeleton.vue';

export default {
    name: 'GatewayList',
    components: {
        EmptyState,
        GatewayItem,
        ListGroup,
        ListGroupItem,
        UiListGroupSkeleton
    },
    data() {
        return {
            gateways: [],
            loading: false,
        };
    },
    mounted() {
        this.getGateways();
    },
    methods: {
        async getGateways() {
            try {
                this.loading = true;

                const { data } = await this.$axios.get(`/_/accounts/${this.$route.query.id}/gateways`);

                this.gateways = data;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        updateGatewayStatus(id, status) {
            try {
                const search_index = this.gateways.findIndex(item => item.id === id);
    
                this.gateways[search_index].active = status;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    },
};
</script>
